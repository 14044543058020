<template>
  <section class="carousel-section ">
    <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">

      <div class="carousel-inner">

        <div class="carousel-item active">
          <!-- <img class="d-block w-100" src="/img/carousel/carousel_3.png"> -->
          <img class="d-block w-100" src="/img/carousel/cheza_leo.png">
        </div>
        <div class="carousel-item">
          <!-- <img class="d-block w-100" src="/img/carousel/comet-crash.png"> -->
          <img class="d-block w-100" src="/img/carousel/freespin1.png">
        </div>
        <div class="carousel-item">
          <!-- <img class="d-block w-100" src="/img/carousel/crash-mamilli.png"> -->
          <img class="d-block w-100" src="/img/carousel/djp1.png">
        </div>
        <!-- <div class="carousel-item ">
          <img class="d-block w-100" src="/img/carousel/f1.png">
        </div> -->
        <div class="carousel-item ">
          <!-- <img class="d-block w-100" src="/img/carousel/carousel_4.png"> -->
          <img class="d-block w-100" src="/img/carousel/karibu1.png">
        </div>
        <!-- <div class="carousel-item ">
          <img class="d-block w-100" src="/img/carousel/JACKPOT.png">
        </div> -->

      </div>
      <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
      </a>
      <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
      </a>
    </div>
  </section>
</template>
<script>
export default {
  name: "Slider",
}
</script>